export default () => {
    /* eslint-disable-next-line no-restricted-globals */
    self.addEventListener('message', async e => {
        if (!e) { return; }

        const sensorValues = e.data[0];

        if (!sensorValues) { return; }

        let angles = sensorValues.angles;
        let widths = sensorValues.widths;
        let leftPots = sensorValues.leftPots;
        let rightPots = sensorValues.rightPots;

        const existingAngles = e.data[1];
        const existingWidths = e.data[2];
        const existingPotHeights = e.data[3];

        for (let index in angles) {
            let value = angles[index];
            let date = new Date(value['_time']);
            console.log(date);
            const timezoneOffset = date.getTimezoneOffset() / 60;
            date.setHours(date.getHours() - timezoneOffset);
            const dateString = date.toISOString().replace('Z', '')

            const sameExist = existingAngles.find(angle => angle.time === dateString.substring(0, 19));

            if (sameExist != null) {
                postMessage(true);
                continue;
            }

            const latitude = value['location'].latitude;
            const longitude = value['location'].longitude;

            const angle = {
                time: dateString,
                latitude: latitude,
                longitude: longitude,
                angle: Math.round(parseFloat(value['_value']) * 100) / 100
            };

            console.log(angle);

            postMessage(['/angle', angle]);
        }

        for (let index in widths) {
            const value = widths[index];

            let date = new Date(value['_time']);
            const timezoneOffset = date.getTimezoneOffset() / 60;
            date.setHours(date.getHours() - timezoneOffset);
            const time = date.toISOString().replace('Z', '');

            const sameExist = existingWidths.find(width => width.time === time.substring(0, 19));

            if (sameExist != null) {
                postMessage(true);
                continue;
            }

            const latitude = value['location'].latitude;
            const longitude = value['location'].longitude;
            let width = {
                width: Math.round(parseFloat(value['_value'])),
                latitude: latitude,
                longitude: longitude,
                time: time
            }

            postMessage(['/width', width]);
        }


        for (let index in leftPots) {
            const value = leftPots[index];

            let date = new Date(value['_time']);
            const timezoneOffset = date.getTimezoneOffset() / 60;
            date.setHours(date.getHours() - timezoneOffset);
            const time = date.toISOString().replace('Z', '');

            const sameExist = existingPotHeights.find(pot => pot.time === time.substring(0, 19)
                                                             && pot.location === 0);

            if (sameExist != null) {
                postMessage(true);
                continue;
            }

            const pot = {
                height: Math.round(parseFloat(value['_value']) * 10) / 10,
                location: 0,
                time: time,
                latitude: value['location'].latitude,
                longitude: value['location'].longitude
            }

            postMessage(['/pot', pot]);
        }

        for (let index in rightPots) {
            const value = rightPots[index];

            let date = new Date(value['_time']);
            const timezoneOffset = date.getTimezoneOffset() / 60;
            date.setHours(date.getHours() - timezoneOffset);
            const time = date.toISOString().replace('Z', '');

            const sameExist = existingPotHeights.find(pot => pot.time === time.substring(0, 19)
                                                             && pot.location === 1);

            if (sameExist != null) {
                postMessage(true);
                continue;
            }

            const pot = {
                height: Math.round(parseFloat(value['_value']) * 10) / 10,
                location: 1,
                time: time,
                latitude: value['location'].latitude,
                longitude: value['location'].longitude
            }

            postMessage(['/pot', pot]);
        }
    });
};

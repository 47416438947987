import { fromJS } from 'immutable';

const initialState = fromJS({
  macadams: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_MACADAMS': {
      return state.set('macadams', fromJS([]));
    }

    case 'ADD_MACADAMS': {
      const sortedMacadams = fromJS(action.macadams).sort((a, b) => {
        return new Date(a.get('date')) - new Date(b.get('date'));
      });
      return state.set('macadams', sortedMacadams);
    }

    case 'REMOVE_MACADAM': {
      const removableMacadam = state.get('macadams').findIndex(macadam => macadam.get('id') === fromJS(action.macadam));
      if (removableMacadam !== -1) {
        return state.set('macadams', state.get('macadams').delete(removableMacadam));
      }
      return state;
    }
  
    case 'ADD_MACADAM': {
     const newMacadam = fromJS(action.macadam);
      if (state.get('macadams').find(macadam => macadam.get('id') === newMacadam.get('id')) === undefined) {
        return state.set('macadams', state.get('macadams').push(newMacadam));
      }
      return state;
    }

    case 'CHANGE_MACADAM': {
      const changingIndex = state.get('macadams').findIndex(macadam => macadam.get('id') === fromJS(action.macadam.id));

      if (changingIndex === -1) return state;

      if (state.get('macadams').get(changingIndex) === fromJS(action.macadam)) {
        return state;
      }
      const newMacadams = state.get('macadams').set(changingIndex, fromJS(action.macadam));
      return state.set('macadams', newMacadams);
    }

    default: {
      return state;
    }
  }
};
export default () => {
    const mode = (values) => {
        return Object.values(
            values.reduce((count, e) => {
              if (!(e in count)) {
                count[e] = [0, e];
              }
              
              count[e][0]++;
              return count;
            }, {})
          ).reduce((a, v) => v[0] < a[0] ? a : v, [0, null])[1];
    }

    const toRadians = (angle) => {
        return angle * (Math.PI / 180);
    }

    const calculateDistance = (latitude1, longitude1, latitude2, longitude2) => {
        const R = 6371e3; // metres
        const φ1 = toRadians(latitude1);
        const φ2 = toRadians(latitude2);
        const Δφ = toRadians(latitude2 - latitude1);
        const Δλ = toRadians(longitude2 - longitude1);
        const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) + Math.cos(φ1) * Math.cos(φ2) *
                  Math.sin(Δλ/2) * Math.sin(Δλ/2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      
        return R * c;
    }

    /* eslint-disable-next-line no-restricted-globals */
    self.addEventListener("message", e => {
        if (!e) { return; }

        const sensorValues = e.data[0];

        if (!sensorValues) { return; }

        let angles = sensorValues.angles;
        let widths = sensorValues.widths;
        let leftPots = sensorValues.leftPots;
        let rightPots = sensorValues.rightPots;

        const maxLength = e.data[3];

        let mostValues;

        if (maxLength === angles.length) {
            mostValues = angles;
        }
        else if (maxLength === widths.length) {
            mostValues = widths;
        }
        else if (maxLength === leftPots.length) {
            mostValues = leftPots;
        }
        else if (maxLength === rightPots.length) {
            mostValues = rightPots;
        }

        let sensorValueLoadingCount = 0;
        const DISTANCE_BETWEEN = 1;

        let currentAngles = [];
        let currentWidths = [];
        let currentLeftPots = [];
        let currentRightPots = [];

        let newAngles = [];
        let newWidths = [];
        let newLeftPots = [];
        let newRightPots = [];

        let lastLatitude = 0;
        let lastLongitude = 0

        for (let index = 0; index < mostValues.length; index++) {
            const value = mostValues[index];
            const latitude = value.location.latitude;
            const longitude = value.location.longitude;
            const distance = calculateDistance(lastLatitude, lastLongitude, latitude, longitude);

            if (index < angles.length) {
                let angle = angles[index];
                currentAngles.push(angle['_value']);

                if (distance >= DISTANCE_BETWEEN) {
                    angle['_value'] = mode(currentAngles);
                    newAngles.push(angle);
                    currentAngles = [];

                    lastLatitude = latitude;
                    lastLongitude = longitude
                }
            }
            
            if (index < widths.length) {
                let width = widths[index];
                currentWidths.push(width['_value']);

                if (distance >= DISTANCE_BETWEEN) {
                    width['_value'] = mode(currentWidths);
                    newWidths.push(width);
                    currentWidths = [];

                    lastLatitude = latitude;
                    lastLongitude = longitude
                }
            }

            if (index < leftPots.length) {
                let leftPot = leftPots[index];
                currentLeftPots.push(leftPot['_value']);

                if (distance >= DISTANCE_BETWEEN) {
                    leftPot['_value'] = mode(currentLeftPots);
                    newLeftPots.push(leftPot);
                    currentLeftPots = [];

                    lastLatitude = latitude;
                    lastLongitude = longitude
                }
            }

            if (index < rightPots.length) {
                let rightPot = rightPots[index];
                currentRightPots.push(rightPot['_value']);

                if (distance >= DISTANCE_BETWEEN) {
                    rightPot['_value'] = mode(currentRightPots);
                    newRightPots.push(rightPot);
                    currentRightPots = [];

                    lastLatitude = latitude;
                    lastLongitude = longitude
                }
            }

            sensorValueLoadingCount = sensorValueLoadingCount + 1;

            postMessage(sensorValueLoadingCount);
        }

        postMessage([
            newAngles,
            newWidths,
            newLeftPots,
            newRightPots
        ]);
    })
};

import React from 'react';
import { connect } from 'react-redux';
import { XYPlot, YAxis, LineSeries, Crosshair, DiscreteColorLegend } from 'react-vis';
import { showMessage } from '../../../../src/message/Actions';
import List from '../../../../src/list/List';
import Excel from '../../../../src/excel/Excel';
import { fetch, paddedNumber } from '../utils';
import { fromJS } from 'immutable';
import './Sensor.css';


const AngleSensor = props => {
  if (props.loading) {
    return <div className='loader'/>;
  }

  const angles = fromJS(props.angles);

  return (
    <div>
      <AngleGraph graphData={props.graphData}
                  crosshairValue={props.crosshairValue}
                  setCrosshairValue={props.setCrosshairValue}
                  resetCrosshairValue={props.resetCrosshairValue}
                  showAmount={props.showAmount}
                  amount={props.dataFilterAmount}
                  width={props.width} height={props.height} />
      <List emptyText={'Ei dataa'}
            header={['Aika', 'Tie', 'Tieosa', 'Paalu', 'Kulma', '']}
            fields={['time#time', 'road_number', 'road_part', 'road_distance', 'angle',
                     'button|Näytä levitys|0#this']}
            functions={[props.selectMacadam]}
            data={angles}/>
      <Excel show={props.makeExcel} toggle={props.toggleMakeExcel}
             name={props.name + ' - Kulmat'}
             headers={['Aika', 'Tie', 'Tieosa', 'Paalu', 'Kulma']}
             dataHeaders={['time', 'road_number', 'road_part', 'road_distance', 'angle']}
             timeField={'time'}
             data={angles}/>
    </div>
  )
};

const AngleGraph = props => {
  if (!props.graphData) {
    return null;
  }
  const crosshair = props.crosshairValue[0] != null ?
    <div id='crosshair-data'>
      <span>{props.crosshairValue[0].time}</span>
      <br/>
      <span>{props.crosshairValue[0].roadPart} / {props.crosshairValue[0].roadDistance}</span>
      <br/>
      <span>{props.crosshairValue[0].angle} Kulma</span>
    </div>
    : null;

  return (
    <div className='center'>
      <XYPlot height={props.height * 0.3} width={props.width * 0.8} onMouseLeave={props.resetCrosshairValue}
              yPadding={20}>
        <YAxis />
        <LineSeries color="blue" data={props.graphData[0]}
                    onNearestX={props.setCrosshairValue}/>
        <Crosshair values={props.crosshairValue}>
          {crosshair}
        </Crosshair>
      </XYPlot>
    </div>
  );
}

const WidthSensor = props => {
  if (props.loading) {
    return <div className='loader'/>;
  }

  const widths = fromJS(props.widths);

  return (
    <div>
      <WidthGraph graphData={props.graphData}
                  crosshairValue={props.crosshairValue}
                  setCrosshairValue={props.setCrosshairValue}
                  resetCrosshairValue={props.resetCrosshairValue}
                  showAmount={props.showAmount}
                  amount={props.dataFilterAmount}
                  width={props.width} height={props.height} />
      <List emptyText={'Ei dataa'}
            header={['Aika', 'Tie', 'Tieosa', 'Paalu', 'Leveys', '']}
            fields={['time#time', 'road_number', 'road_part', 'road_distance', 'width',
                     'button|Näytä levitys|0#this']}
            functions={[props.selectMacadam]}
            data={widths}/>
      <Excel show={props.makeExcel} toggle={props.toggleMakeExcel}
             name={props.name + ' - Leveydet'}
             headers={['Aika', 'Tie', 'Tieosa', 'Paalu', 'Leveys']}
             dataHeaders={['time', 'road_number', 'road_part', 'road_distance', 'width']}
             timeField={'time'}
             data={widths}/>
    </div>
  )
};

const WidthGraph = props => {
  if (!props.graphData) {
    return null;
  }
  const crosshair = props.crosshairValue[0] != null ?
    <div id='crosshair-data'>
      <span>{props.crosshairValue[0].time}</span>
      <br/>
      <span>{props.crosshairValue[0].roadPart} / {props.crosshairValue[0].roadDistance}</span>
      <br/>
      <span>{props.crosshairValue[0].width} Leveys (mm)</span>
    </div>
    : null;

  return (
    <div className='center'>
      <XYPlot height={props.height * 0.3} width={props.width * 0.8} onMouseLeave={props.resetCrosshairValue}
              yPadding={20}>
        <YAxis />
        <LineSeries color="blue" data={props.graphData[0]}
                    onNearestX={props.setCrosshairValue}/>
        <Crosshair values={props.crosshairValue}>
          {crosshair}
        </Crosshair>
      </XYPlot>
    </div>
  );
}

const PotHeightSensor = props => {
  if (props.loading) {
    return <div className='loader'/>;
  }

  const potHeights = fromJS(props.potHeights);

  return (
    <div>
      <PotHeightGraph graphData={props.graphData}
                     crosshairValue={props.crosshairValue}
                     setCrosshairValue={props.setCrosshairValue}
                     resetCrosshairValue={props.resetCrosshairValue}
                     showAmount={props.showAmount}
                     amount={props.dataFilterAmount}
                     width={props.width} height={props.height}
                     show={props.show} sensors={props.sensors} />
      <List emptyText={'Ei dataa'}
            header={['Aika', 'Tie', 'Tieosa', 'Paalu', 'Sijainti', 'Korkeus (mm)', '']}
            fields={['time#time', 'road_number', 'road_part', 'road_distance',
                      'location', 'height', 'button|Näytä levitys|0#this']}
            functions={[props.selectMacadam]}
            data={potHeights}/>
      <Excel show={props.makeExcel} toggle={props.toggleMakeExcel}
             name={props.name + ' - Korkeudet'}
             headers={['Aika', 'Tie', 'Tieosa', 'Paalu', 'Sijainti', 'Korkeus (mm)']}
             dataHeaders={['time', 'road_number', 'road_part', 'road_distance', 'location',
                           'height']}
             timeField={'time'}
             data={potHeights}/>
    </div>
  )
};

const PotHeightGraph = props => {
  const crosshair = props.crosshairValue[0] != null ?
    <div id='crosshair-data'>
      <span>{props.crosshairValue[0].time}</span>
      <br/>
      <span>{props.crosshairValue[0].roadPart} / {props.crosshairValue[0].roadDistance}</span>
      <br/>
      <span>{props.crosshairValue[0].height} Vasen korkeus (mm)</span>
      <br/>
      <span>{props.crosshairValue[0].height2} Oikea korkeus (mm)</span>
    </div>
    : null;

  const ITEMS = [
    {title: 'Vasen', color: "blue", strokeDasharray: "dashed"},
    {title: 'Oikea', color: 'green', strokeDasharray: "dashed"},
  ];

  return (
    <div className='center'>
      <DiscreteColorLegend orientation="horizontal" width={300} items={ITEMS} />
      {'Näytä: '}
      <label>
        Vasen
        <input type='checkbox' name='side' value='0'
          onChange={props.show.bind(this, 0)}
          checked={props.sensors.includes(0)}/>
      </label>
      <label>
        Oikea
        <input type='checkbox' name='side' value='1'
          onChange={props.show.bind(this, 1)}
          checked={props.sensors.includes(1)}/>
      </label>
      <XYPlot height={props.height * 0.3} width={props.width * 0.8} onMouseLeave={props.resetCrosshairValue}
              yPadding={20} xType='time'>
        <YAxis />
        { props.sensors.includes(0) ?
          <LineSeries color='blue' data={props.graphData[0]}
                      onNearestX={props.setCrosshairValue} />
          : null
        }
        { props.sensors.includes(1) ?
          <LineSeries color='green' data={props.graphData[1]}
                      onNearestX={props.setCrosshairValue} />
          : null
        }
        <Crosshair values={props.crosshairValue}>
          {crosshair}
        </Crosshair>
      </XYPlot>
    </div>
  );
}


class Sensors extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showSensor: 0,
      sensorName: 'angles',
      loading: false,
      angles: [],
      widths: [],
      potHeights: [],
      makeExcel: false,
      crosshairValue: [],
      dataFilterAmount: 1,
      sensors: []
    };

    this.showSensor = this.showSensor.bind(this);
    this.toggleMakeExcel = this.toggleMakeExcel.bind(this);
    this.setAngleCrosshairValue = this.setAngleCrosshairValue.bind(this);
    this.setWidthCrosshairValue = this.setWidthCrosshairValue.bind(this);
    this.setPotHeightCrosshairValue = this.setPotHeightCrosshairValue.bind(this);
    this.resetCrosshairValue = this.resetCrosshairValue.bind(this);
    this.showAmount = this.showAmount.bind(this);
    this.show = this.show.bind(this);
  }

  componentDidMount() {
    if (this.props.selectedConstructionSite == null) return;

    const name = this.props.selectedConstructionSite.get('name').length > 20 ?
                   this.props.selectedConstructionSite.get('id')
                   : this.props.selectedConstructionSite.get('name');

    this.setState({ name: name });
    
    if (this.props.timeRangeStart !== '' && this.props.timeRangeEnd !== '') {
      this.getAngles(this.props.selectedConstructionSite.get('id'), this.props.timeRangeStart, this.props.timeRangeEnd);
      this.getWidths(this.props.selectedConstructionSite.get('id'), this.props.timeRangeStart, this.props.timeRangeEnd);
      this.getPotHeights(this.props.selectedConstructionSite.get('id'), this.props.timeRangeStart, this.props.timeRangeEnd);
    }
    else {
      this.getAngles(this.props.selectedConstructionSite.get('id'));
      this.getWidths(this.props.selectedConstructionSite.get('id'));
      this.getPotHeights(this.props.selectedConstructionSite.get('id'));
    }
  }

  componentDidUpdate(lastProps, lastState) {
    if (this.props.selectedConstructionSite === lastProps.selectedConstructionSite &&
        this.props.timeRangeStart === lastProps.timeRangeStart &&
        this.props.timeRangeEnd === lastProps.timeRangeEnd &&
        this.state.showSensor === lastState.showSensor) {
      return;
    }

    if (this.props.selectedContract == null || this.props.selectedConstructionSite == null) {
      this.setState({ 
        angles: [],
        widths: [],
        potHeights: [],
        angleGraphData: [[]],
        angleVisibleGraphData: [[]],
        widthGraphData: [[]],
        widthVisibleGraphData: [[]],
        potHeightGraphData: [[], []],
        potHeightVisibleGraphData: [[], []]
      });

      return;
    }

    if (this.props.selectedConstructionSite !== lastProps.selectedConstructionSite) {
      const name = this.props.selectedConstructionSite.get('name').length > 20 ?
                    this.props.selectedConstructionSite.get('id')
                    :
                    this.props.selectedConstructionSite.get('name');

      this.setState({ name: name });
    }
    else if (this.props.timeRangeStart === lastProps.timeRangeStart && 
             this.props.timeRangeStart === lastProps.timeRangeStart) {
      return;
    }

    if (this.props.timeRangeStart !== '' && this.props.timeRangeEnd !== '') {
      this.getAngles(this.props.selectedConstructionSite.get('id'), this.props.timeRangeStart, this.props.timeRangeEnd);
      this.getWidths(this.props.selectedConstructionSite.get('id'), this.props.timeRangeStart, this.props.timeRangeEnd);
      this.getPotHeights(this.props.selectedConstructionSite.get('id'), this.props.timeRangeStart, this.props.timeRangeEnd);
    }
    else {
      this.getAngles(this.props.selectedConstructionSite.get('id'));
      this.getWidths(this.props.selectedConstructionSite.get('id'));
      this.getPotHeights(this.props.selectedConstructionSite.get('id'));
    }
  }

  componentWillUnmount() {
    if (this.socket != null) this.socket.close();
  }

  getAngles(site, startTime, endTime) {
    this.setState({
      loadingAngles: true,
      dataFilterAmount: 1
    });

    let url;
    
    if (startTime != null) {
      url = '/angle?site=' + site + '&timestart=' + startTime
            + '&timeend=' + endTime;
    }
    else {
      url = '/angle?site=' + site;
    }

    fetch(url).then(data => {
      data = data.sort((a, b) => {
        return new Date(a.time) - new Date(b.time);
      });

      let graphData = [[]];

      for (let index in data) {
        const angle = data[index];
        const x = new Date(angle.time).getTime();
        graphData[0].push({x: x, y: angle.angle});
      }

      this.setState({ 
        angles: data,
        angleGraphData: graphData,
        angleVisibleGraphData: graphData
      })
    }).catch(error => {
      console.log(error);
    }).finally(() => {
      this.setState({ loadingAngles: false })
    });
  }

  getWidths(site, startTime, endTime) {
    this.setState({
      loadingWidths: true,
      dataFilterAmount: 1
    });

    let url = '/width/macadam?site=' + site;
    
    if (startTime != null) {
      url += '&timestart=' + startTime + '&timeend=' + endTime;
    }

    fetch(url).then(data => {
      data = data.sort((a, b) => {
        return new Date(a.time) - new Date(b.time);
      });

      let graphData = [[]];

      for (let index in data) {
        const width = data[index];
        const x = new Date(width.time).getTime();
        graphData[0].push({x: x, y: width.width});
      }

      this.setState({ 
        widths: data,
        widthGraphData: graphData,
        widthVisibleGraphData: graphData
      })
    }).catch(error => {
      console.log(error);
    }).finally(() => {
      this.setState({ loadingWidths: false })
    });
  }

  getPotHeights(site, startTime, endTime) {
    this.setState({
      loadingPotHeights: true,
      dataFilterAmount: 1,
      sensors: [0, 1]
    });

    let url;
    
    if (startTime != null) {
      url = '/pot?site=' + site + '&timestart=' + startTime
            + '&timeend=' + endTime;
    }
    else {
      url = '/pot?site=' + site;
    }

    fetch(url).then(data => {
      data = data.sort((a, b) => {
        return new Date(a.time) - new Date(b.time);
      });

      let graphData = [[], []];

      for (let index in data) {
        const potHeight = data[index];

        const x = new Date(potHeight.time).getTime();

        if (potHeight.location === 0) {
          potHeight.location = 'Vasen';
          graphData[0].push({x: x, y: potHeight.height});
        }
        else  {
          potHeight.location = 'Oikea';
          graphData[1].push({x: x, y: potHeight.height});
        }
      }

      this.setState({ 
        potHeights: data,
        potHeightGraphData: graphData,
        potHeightVisibleGraphData: graphData
      });
    }).catch(error => {

    }).finally(() => {
      this.setState({ loadingPotHeights: false })
    });
  }

  showSensor(sensor) {
    let sensorName;

    if (sensor === 0) {
      sensorName = 'angles';
    }
    else if (sensor === 1) {
      sensorName = 'widths';
    }
    else {
      sensorName = 'potHeights';
    }

    this.setState({
      showSensor: sensor,
      sensorName: sensorName
    });
  }

  toggleMakeExcel() {
    this.setState({ 
      makeExcel: !this.state.makeExcel
    })
  }

  setAngleCrosshairValue(value) {
    const angle = this.state.angles.find(angle => new Date(angle.time).getTime() === value.x);

    if (angle == null) {
      return;
    }

    const date = new Date(angle['time']);
    const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
      + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());

    this.setState({
      crosshairValue: [{
         x: value.x,
         time: time,
         roadPart: angle['road_part'],
         roadDistance: angle['road_distance'],
         angle: angle['angle'],
      }]
    });
  }

  setWidthCrosshairValue(value) {
    const width = this.state.widths.find(width => new Date(width.time).getTime() === value.x);

    if (width == null) {
      return;
    }

    const date = new Date(width['time']);
    const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
      + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());

    this.setState({
      crosshairValue: [{
         x: value.x,
         time: time,
         roadPart: width['road_part'],
         roadDistance: width['road_distance'],
         width: width['width'],
      }]
    });
  }

  setPotHeightCrosshairValue(value, {index}) {
    const potHeight = this.state.potHeights.filter(potHeight => new Date(potHeight.time).getTime() === value.x);

    const date = new Date(potHeight[0]['time']);
    const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
      + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());

    this.setState({
      crosshairValue: [{
         x: value.x,
         time: time,
         roadPart: potHeight[0]['road_part'],
         roadDistance: potHeight[0]['road_distance'],
         height: potHeight[0]['height'],
         height2: potHeight[1] ? potHeight[1]['height'] : null
      }]
    });
  }

  resetCrosshairValue() {
    this.setState({crosshairValue: []});
  }

  showAmount(amount) {
    let angleGraphData = [[]];
    let widthGraphData = [[]];
    let potHeightGraphData = [[], []];

    for (let i = 0; i < this.state.angleGraphData[0].length / amount; i++) {
      const data = this.state.angleGraphData[0][i * amount];
      angleGraphData[0].push({x: data.x, y: data.y});
    }

    for (let i = 0; i < this.state.widthGraphData[0].length / amount; i++) {
      const data = this.state.widthGraphData[0][i * amount];
      widthGraphData[0].push({x: data.x, y: data.y});
    }

    for (let g = 0; g < this.state.potHeightGraphData.length; g++) {
      for (let i = 0; i < this.state.potHeightGraphData[g].length / amount; i++) {
        const data = this.state.potHeightGraphData[g][i * amount];
        potHeightGraphData[g].push({x: data.x, y: data.y});
      }
    }

    this.setState({
      dataFilterAmount: amount,
      angleVisibleGraphData: angleGraphData,
      widthVisibleGraphData: widthGraphData,
      potHeightVisibleGraphData: potHeightGraphData
    });
  }

  show(value, event) {
    let sensors = this.state.sensors.slice();

    if (event.target.checked) {
      sensors.push(value);
    }
    else {
      const index = sensors.findIndex(sensor => sensor === value);
      sensors.splice(index, 1);
    }

    this.setState({ sensors: sensors });
  }

  render() {
    const width = window.innerWidth
                  || document.documentElement.clientWidth
                  || document.body.clientWidth;
    
    const height = window.innerHeight
                  || document.documentElement.clientHeight
                  || document.body.clientHeight;

    return (
      <div>
        <div className='center'>
            <div className={"sensor-select" + (this.state.showSensor === 0 ? ' selected' : '')}
                 onClick={this.state.showSensor === 0 ? null : this.showSensor.bind(null, 0)}>
              Kulma
            </div>
            <div className={"sensor-select" + (this.state.showSensor === 1 ? ' selected' : '')}
                 onClick={this.state.showSensor === 1 ? null : this.showSensor.bind(null, 1)}>
              Leveys
            </div>
            <div className={"sensor-select" + (this.state.showSensor === 2 ? ' selected' : '')}
                 onClick={this.state.showSensor === 2 ? null : this.showSensor.bind(null, 2)}>
              Korkeus
            </div>
        </div>
        <div className='center'>
          <button onClick={this.toggleMakeExcel}
                  disabled={this.state.name == null}>
            Luo raportti
          </button>
          <button onClick={this.props.addSensorDataToMap.bind(null, this.state[this.state.sensorName])}
                  disabled={this.state.name == null}>
            Näytä kartalla
          </button>
        </div>
        <br/>
        { this.state.loading ? <div className='loader'/> : 
          <div className='center'>
            {'Näytä: '}
            <label>
              1:1
              <input type='radio' name='amount' value='1'
                     onChange={this.showAmount.bind(this, 1)}
                     defaultChecked={this.state.dataFilterAmount === 1} />
            </label>
            <label>
              1:10
              <input type='radio' name='amount' value='10'
                    onChange={this.showAmount.bind(this, 10)}
                    defaultChecked={this.state.dataFilterAmount === 10} />
            </label>
            <label>
              1:50
              <input type='radio' name='amount' value='50'
                     onChange={this.showAmount.bind(this, 50)}
                     defaultChecked={this.state.dataFilterAmount === 50} />
            </label>
            <label>
              1:100
              <input type='radio' name='amount' value='100'
                     onChange={this.showAmount.bind(this, 100)}
                     defaultChecked={this.state.dataFilterAmount === 100} />
            </label>
            { this.state.showSensor === 0 ?
              <AngleSensor angles={this.state.angles}
                           selectMacadam={this.props.selectMacadam}
                           name={this.state.name}
                           makeExcel={this.state.makeExcel}
                           toggleMakeExcel={this.toggleMakeExcel}
                           graphData={this.state.angleVisibleGraphData}
                           crosshairValue={this.state.crosshairValue}
                           setCrosshairValue={this.setAngleCrosshairValue}
                           resetCrosshairValue={this.resetCrosshairValue}
                           showAmount={this.showAmount}
                           dataFilterAmount={this.state.dataFilterAmount}
                           width={width}
                           height={height}
                           show={this.show}
                           sensors={this.state.sensors}
                           loading={this.state.loadingWidths} />
            : null}
            { this.state.showSensor === 1 ?
              <WidthSensor widths={this.state.widths}
                           selectMacadam={this.props.selectMacadam}
                           name={this.state.name}
                           makeExcel={this.state.makeExcel}
                           toggleMakeExcel={this.toggleMakeExcel}
                           graphData={this.state.widthVisibleGraphData}
                           crosshairValue={this.state.crosshairValue}
                           setCrosshairValue={this.setWidthCrosshairValue}
                           resetCrosshairValue={this.resetCrosshairValue}
                           showAmount={this.showAmount}
                           dataFilterAmount={this.state.dataFilterAmount}
                           width={width}
                           height={height}
                           loading={this.state.loadingWidths} />
            : null}
            { this.state.showSensor === 2 ?
              <PotHeightSensor potHeights={this.state.potHeights}
                               selectMacadam={this.props.selectMacadam}
                               name={this.state.name}
                               makeExcel={this.state.makeExcel}
                               toggleMakeExcel={this.toggleMakeExcel}
                               graphData={this.state.potHeightVisibleGraphData}
                               crosshairValue={this.state.crosshairValue}
                               setCrosshairValue={this.setPotHeightCrosshairValue}
                               resetCrosshairValue={this.resetCrosshairValue}
                               showAmount={this.showAmount}
                               dataFilterAmount={this.state.dataFilterAmount}
                               width={width}
                               height={height}
                               show={this.show}
                               sensors={this.state.sensors}
                               loading={this.state.loadingPotHeights} />
            : null}
          </div>
        }
      </div>
    );
  }
}

export default connect(state => ({
  selectedContract: state.contractSelect.get('selectedContract'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
  timeRangeStart: state.timeRange.get('startTime'),
  timeRangeEnd: state.timeRange.get('endTime'),
  macadams: state.macadam.get('macadams'),
  organizationId: state.login.get('user') ? state.login.get('user').get('organizationId') : null,
}), {showMessage })(Sensors);

import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import { useMap, MapContainer, Marker, Popup, TileLayer, Polyline, CircleMarker, useMapEvent } from 'react-leaflet';
import { connect } from 'react-redux';
import ConstructionSiteLatestSelect from './ConstructionSiteLatestSelect';
import Sensors from './Sensors';
import { showMessage } from '../../../../src/message/Actions';
import List from '../../../../src/list/List';
import Excel from '../../../../src/excel/Excel';
import MapTiles from '../../../../src/mapTiles/MapTiles';
import ContractSelect from '../../../../src/contractSelect/ContractSelect';
import ConstructionSiteSelect from '../../../../src/constructionSiteSelect/ConstructionSiteSelect';     
import { clearMacadams, addMacadams, addMacadam, removeMacadam, changeMacadam } from './Actions';
import { fetch, Socket, toRadians, toDegrees, toETRSTM35FIN, toWGS84,
         paddedNumber, timer, getRoadData, getRoadCoordinates } from '../utils';
import './Macadam.css';
import TimeRange from '../../../../src/timeRange/TimeRange';
import 'leaflet/dist/leaflet.css';

const MapOrListOrSensors = props => {
  var macadams = props.macadams;

  if (props.site === null) {
    macadams = props.macadams.clear();
  }

  if (props.state === 0) return <MapView macadams={props.macadams}
                                         site={props.site}
                                         mapPaths={props.mapPaths}
                                         mapZoom={props.mapZoom}
                                         mapPosition={props.mapPosition}
                                         selectedPath={props.selectedPath}
                                         sensorValues={props.sensorValues} />;

  if (props.state === 2) return <Sensors selectMacadam={props.selectMacadam}
                                         addSensorDataToMap={props.addSensorDataToMap} />;

  let totalTruckMass = 0;
  macadams.map(macadam => {
    totalTruckMass += macadam.get('truck_mass');
    return null;
  });

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>
              Kuorma tonnit
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Yhteensä:</b>
            </td>
            <td>
              {Math.round(totalTruckMass * 10) / 10}
            </td>
          </tr>
        </tbody>
      </table>
      <List emptyText={'Ei murskeita'}
            header={['Urakoitsija', 'Aika', 'Aloitus-lopetus', 'Suunta / ajorata / kaista', 'Pituus', 'Ajoneuvo', 'Kuorman koko',
                     'Kerroin', 'Korkeus (mm)', 'Lajike', 'Kuoppa', 'Huomiot']}
            fields={['organization.name', 'date#time', 'roadPaths', 'roadPositions', 'length',
                     'truck.register_number', 'truck_mass', 'multiplier', 'height', 'variety', 'pit', 'attentions']}
            data={macadams}/>
    </div>
  );
};


export const MapView = (properties) => {

  const props = properties;

  let map;
  let position = [64.1, 26.5];
  let zoom = 6;
  let watchID;

  const [showedWarning, setShowedWarning] = useState(false);
  const [directions, setDirections] = useState([1, 2]);
  const [lines, setLines] = useState([]);
  const [sensorValues, setSensorValues] = useState([]);
  const [yourLatitude, setYourLatitude] = useState(null);
  const [yourLongitude, setYourLongitude] = useState(null);
  const [height, setHeight] = useState(window.innerHeight * 0.6);
  const [mapTilesUrl, setMapTilesUrl] = useState('');
  const [mapTilesAttribution, setMapTilesAttribution] = useState('');
  const [maxZoom, setMaxZoom] = useState(16);
  const [selectedLine, setSelectedLine] = useState();

  useEffect(() => {
    if (props.mapPosition) {
      position = props.mapPosition;
      zoom = props.mapZoom;
    }

    window.addEventListener("resize", updateDimensions);

    update(props);

    updateSensorValues(props.sensorValues);

    if (!navigator.geolocation) {
      return;
    }

    watchID = navigator.geolocation.watchPosition(position => {
      setYourLatitude(position.coords.latitude);
      setYourLongitude(position.coords.longitude);
    }, error => {
      return;
    }, { enableHighAccuracy: true });

    return () => {
      navigator.geolocation.clearWatch(watchID);
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  const update = async (props) => {
    if (props.mapPaths.length === 0) {
      setLines([]);
      return;
    }

    let lines = [];

    for (let i = 0; i < props.macadams.size; i++) {
      const macadam = props.macadams.get(i);

      for (let p = 0; p < macadam.get('paths').size; p++) {
        const path = macadam.get('paths').get(p);

        if (!(directions.includes(path.get('direction'))) || !path.get('start_latitude')) continue;

        const date = new Date(path.get('date'));
        const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
          + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());

        let color;

        if (selectedLine === path.get('id')) {
          color = '#00FFFF';
        }
        else if (path.get('direction') === 1) {
          color = '#0000FF';
        }
        else {
          color = '#00FF00';
        }

        const text = (
          <span>
            {time}
            <br />
            Aloitus: {path.get('road')} / Osa: {path.get('start_part')} / Paalu: {path.get('start_distance')}
            <br />
            Lopetus: {path.get('road')} / Osa: {path.get('end_part') || '- '} / Paalu: {path.get('end_distance') || '-'}
            <br />
            Ajorata: {path.get('roadway')}
            <br />
            Suunta: {path.get('direction')}
            <br />
            Kaista: {path.get('lane')}
            <br />
            Rekisterinumero: {macadam.get('truck').get('register_number')}
            <br />
            {macadam.get('paths').size > 1 ?
              <div>
                Arvio käytetystä massasta: {Math.round(macadam.get('truck_mass') / macadam.get('paths').size * 100) / 100}
              </div>
              : null
            }
            Kuorman koko: {Math.round(macadam.get('truck_mass') * 100) / 100}
            <br />
          </span>
        );

        const mapPath = props.mapPaths[path.get('id')];

        if (mapPath.length === 1) {
          mapPath.push(mapPath[0]);
        }

        lines.push(
          <Polyline key={path.get('id')}
            positions={mapPath}
            weight={10}
            opacity={0.6}
            color={color}
            onClick={selectLine.bind(null, path.get('id'))}
            ref={ref => props.selectedPath === path.get('id') && setTimeout(() =>
              goToPath(ref, path.get('id')))}
          >
            <Popup autoPan={false}>
              {text}
            </Popup>
          </Polyline>
        );
      }
    }

    setLines(lines);
  }

  const updateSensorValues = (sensorValues) => {
    if (sensorValues.length === 0) {
      setSensorValues([]);
      return;
    }

    let sensorValuesList = [];

    for (let index in sensorValues) {
      const sensorValue = sensorValues[index];

      if (sensorValue.height != null && index !== 0 && index % 2 !== 0) {
        continue;
      }

      const date = new Date(sensorValue.time);
      const time = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
        + ' ' + paddedNumber(date.getHours()) + ':' + paddedNumber(date.getMinutes()) + ':' + paddedNumber(date.getSeconds());

      const text = (
        <span>
          {time}
          <br />
          {sensorValue.road_number ?
            'Aloitus: ' + sensorValue.road_number + '/ Osa: ' + sensorValue.road_part + '/ Paalu:' + sensorValue.road_distance
            : null}
          <br />
          {sensorValue.latitude} / {sensorValue.longitude}
          <br />
          {sensorValue.angle != null ? 'Kulma: ' + sensorValue.angle : null}
          {sensorValue.width != null ? 'Leveys: ' + sensorValue.width : null}
          {sensorValue.height != null ? 'Korkeus vasen: ' + sensorValue.height + ' Korkeus oikea: ' + sensorValues[parseInt(index) + 1].height
            : null}
        </span>
      );

      sensorValuesList.push(
        <CircleMarker key={sensorValue.id}
          center={{ lat: sensorValue.latitude, lng: sensorValue.longitude }}
          radius={10}
          color={'#00FF00'}
          fillColor={'#00FF00'} >
          <Popup autoPan={false}>
            {text}
          </Popup>
        </CircleMarker>
      );
    }

    setSensorValues(sensorValuesList);
  }

  const goToPath = (ref, id) => {
    if (ref == null) return;
    const coordinates = ref.getLatLngs();
    const pos = coordinates[Math.floor(coordinates.length / 2)];
    map.setView([pos.lat, pos.lng], 14);
    timer(100).then(() => {
      ref.openPopup();
      selectLine(id);
    })
  }

  const updateDimensions = () => {
    const height = window.innerHeight * 0.6;
    setHeight(height);
  }

  const changeDirection = (value, event) => {
    let dirs = directions.slice();

    if (event.target.checked) {
      dirs.push(value);
    }
    else {
      const index = dirs.findIndex(direction => direction === value);
      dirs.splice(index, 1);
    }

    setDirections(dirs);
  }

  const selectLine = (index) => {
    setSelectedLine(index);
  }

  function MapControl() {
    map = useMap();

    if (props.mapPosition && props.mapZoom) {
      map.setView(props.mapPosition, props.mapZoom);
    }

    useMapEvent('click', (event) => {
      selectLine.bind(null, null);
    });

    const mapCenter = map.getCenter();
    const converted = toETRSTM35FIN(mapCenter.lat, mapCenter.lng);
    const mapTiles = MapTiles(converted.x, converted.y);

    if (mapTilesUrl !== mapTiles.url) {
      setMapTilesUrl(mapTiles.url);
      setMapTilesAttribution(mapTiles.attribution);
      setMaxZoom(mapTiles.maxZoom);
    }
  }

  useEffect(() => {
    update(props);
  }, [directions, selectedLine, props.mapPaths, props.selectedPath])

  return (
    <div>
      <div className='center'>
        {'Suunnat: '}
        <label>
          1
          <input id='direction1' type='checkbox' name='direction' value='1'
            onChange={changeDirection.bind(this, 1)}
            defaultChecked={directions.includes(1)} />
        </label>
        <label>
          2
          <input id='direction2' type='checkbox' name='direction' value='2'
            onChange={changeDirection.bind(this, 2)}
            defaultChecked={directions.includes(2)} />
        </label>
      </div>
      <div style={{ height: height }}>
        <MapContainer id="map-area" center={position} zoom={zoom} maxZoom={maxZoom}>
          <MapControl />
          <TileLayer url={mapTilesUrl}
            attribution={mapTilesAttribution}
            maxZoom={maxZoom} />
          {props.yourLatitude != null ? (
            <Marker position={[props.yourLatitude, props.yourLongitude]}
              icon={new L.Icon({
                iconUrl: 'your_location.gif',
                iconSize: [18, 43],
                iconAnchor: [9, 43],
                popupAnchor: [null, -43]
              })}>
              <Popup>
                <span>{props.yourLatitude}, {props.yourLongitude}</span>
              </Popup>
            </Marker>
          ) : null}
          {lines}
          {sensorValues}
        </MapContainer>
      </div>
      {showedWarning ? 'Yhtä tai useampaa kuormaa ei voida näyttää sijainti tiedon virheellisyyden vuoksi.' : ''}
    </div>
  );
};


class Macadams extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      state: 0,
      loading: false,
      makeExcel: false,
      mapPaths: [],
      sensorValues: []
    };

    this.changeState = this.changeState.bind(this);
    this.toggleMakeExcel = this.toggleMakeExcel.bind(this);
    this.selectMacadam = this.selectMacadam.bind(this);
    this.addSensorDataToMap = this.addSensorDataToMap.bind(this);
  }

  componentDidMount() {
    if (this.socket == null && typeof(WebSocket) !== 'undefined') {
      this.socket = Socket('/data/macadam');
      this.socket.onmessage = function(e) {
        const data = JSON.parse(e.data);
        if (data['operation'] === 'create') {
          if (this.props.selectedConstructionSite.get('id') !== data.model.constructionSiteId) {
            return;
          }
          this.props.addMacadam(data.model);
        }
        else if (data['operation'] === 'update') {
          if (this.props.selectedConstructionSite.get('id') !== data.model.construction_site.id) {
            return;
          }
          this.props.changeMacadam(data.model);
        }
        else if (data['operation'] === 'delete') {
          this.props.removeMacadam(data.model);
        }
      }.bind(this)
    }
    if (!this.props.selectedConstructionSite) return;
    if (this.props.timeRangeStart !== '' && this.props.timeRangeEnd !== '') {
      this.getMacadams(this.props.selectedConstructionSite.get('id'), this.props.timeRangeStart, this.props.timeRangeEnd);
    }
    else {
      this.getMacadams(this.props.selectedConstructionSite.get('id'));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.macadams !== nextProps.macadams) {
      this.getMapPaths(nextProps.macadams, nextProps.selectedConstructionSite ? 
                       nextProps.selectedConstructionSite.get('id') : null);
    }
    if (this.props.selectedConstructionSite === nextProps.selectedConstructionSite &&
        this.props.timeRangeStart === nextProps.timeRangeStart &&
        this.props.timeRangeEnd === nextProps.timeRangeEnd) return;
    if (nextProps.selectedContract == null || nextProps.selectedConstructionSite == null) {
      this.props.clearMacadams();
      return;
    }
    if (nextProps.timeRangeStart !== '' && nextProps.timeRangeEnd !== '') {
      this.getMacadams(nextProps.selectedConstructionSite.get('id'), nextProps.timeRangeStart, nextProps.timeRangeEnd);
    }
    else {
      this.getMacadams(nextProps.selectedConstructionSite.get('id'));
    }
  }

  componentWillUnmount() {
    if (this.socket != null) this.socket.close();
  }

  async getMacadams(site, startTime, endTime) {
    this.setState({ loading: true });

    const allPaths = await fetch('/paths/macadam/site/' + site);

    let url;

    if (startTime != null) {
      url = '/macadams?site=' + site + '&timestart=' + startTime
            + '&timeend=' + endTime;
    }
    else {
      url = '/macadams?site=' + site;
    }

    fetch(url).then(data => {
      for (let index in data) {
        let macadam = data[index];
        if (macadam.paths_id) {
          try {
            const paths = allPaths.filter(path => path.paths_id === macadam.paths_id);
            let roadPaths = '';
            let roadPositions = '';
            let length = 0;

            for (let p in paths) {
              const path = paths[p];

              roadPaths += path.start_part + ' / ' + path.start_distance + ' - ' +
                           (path.end_part ? (path.end_part + ' / ' + path.end_distance) + ' , ' : '');
              roadPositions += path.direction + ' / ' + path.roadway + ' / ' + path.lane +
                               ' , ';
              length += path.length;
            }

            macadam.roadPaths = roadPaths.substring(0, roadPaths.length - 3);
            macadam.roadPositions = roadPositions.substring(0, roadPositions.length - 3);
            macadam.length = length;
            macadam.paths = paths;
          } catch(error) {
            macadam.paths = [];
          }
        }
        else {
          macadam.paths = [];
        }
      }

      this.props.addMacadams(data);
      this.setState({
        loading: false
      });
    }).catch(error => {
      this.props.showMessage('Virhe', 'Palvelimeen ei saatu yhteyttä', 'Error');
    });
  }

  changeState(state) {
    this.setState({
      state: state
    });
  }

  toggleMakeExcel() {
    this.setState({ 
      makeExcel: !this.state.makeExcel
    })
  }

  async getMapPaths(macadams, site) {
    this.setState({ mapPaths: [] });

    let paths = [];
    let x = 0;
    let y = 0;
    let z = 0;
    let coordinateCount = 0;
    let zoom = null;
    let position = null;
    let allPoints = [];

    if (site != null) {
      try {
        allPoints = await fetch('/points/macadam/site/' + site);
      } catch(err) {}
    }

    for (let i = 0; i < macadams.size; i++) {
      const macadam = macadams.get(i);

      for (let p = 0; p < macadam.get('paths').size; p++) {
        const path =  macadam.get('paths').get(p);

        if (!path.get('start_latitude')) continue;

        const startLatitude = toRadians(path.get('start_latitude'));
        const startLongitude = toRadians(path.get('start_longitude'));
        x += Math.cos(startLatitude) * Math.cos(startLongitude);
        y += Math.cos(startLatitude) * Math.sin(startLongitude);
        z += Math.sin(startLatitude);
        coordinateCount++;

        let positions = [];

        if (path.get('end_latitude')) {
          const allPathPoint = allPoints.filter(point => point.path_id === path.get('id'));

          if (allPathPoint.length !== 0) {
            allPathPoint.forEach(point => {
              positions.push([point.latitude, point.longitude])
            });
            if (allPathPoint[allPathPoint.length - 1].road_distance !== path.get('end_distance')) {
              positions.push([path.get('end_latitude'), path.get('end_longitude')])
            }
          }
          else {
            positions = [[path.get('start_latitude'), path.get('start_longitude')],
                        [path.get('end_latitude'), path.get('end_longitude')]];
          }

          const endLatitude = toRadians(path.get('end_latitude'));
          const endLongitude = toRadians(path.get('end_longitude'));
          x += Math.cos(endLatitude) * Math.cos(endLongitude);
          y += Math.cos(endLatitude) * Math.sin(endLongitude);
          z += Math.sin(endLatitude);
          coordinateCount++;
        }
        else {
          positions = [[path.get('start_latitude'), path.get('start_longitude')],
                      [path.get('start_latitude'), path.get('start_longitude')]]
        }

        if (path.get('direction') === 2) {
          if (path.get('start_part') > path.get('end_part') ||
              path.get('start_distance') > path.get('end_distance')) {
            positions.reverse();
          }

          positions = await this.get2DirectionPath(positions); 
        }

        paths[path.get('id')] = positions;
      }
    }

    if (coordinateCount !== 0) {
      zoom = 15;

      x = x / coordinateCount;
      y = y / coordinateCount;
      z = z / coordinateCount;

      const centralLongitude = Math.atan2(y, x);
      const centralSquareRoot = Math.sqrt(x * x + y * y);
      const centralLatitude = Math.atan2(z, centralSquareRoot);

      position = [centralLatitude * 180 / Math.PI, centralLongitude * 180 / Math.PI];
    }
    else {
      zoom = 6;
      position = [64.1, 26.5];
    }

    this.setState({
      mapPaths: paths,
      mapZoom: zoom,
      mapPosition: position
    });
  }

  async get2DirectionPath(path) {
    let newPath = []
    let lastAngle;

    if (path.length > 1 && path[0][0] !== path[1][0]) {
      for (let index in path) {
        index = parseInt(index, 10);
        const point = path[index];

        if (index !== path.length - 1) {
          const point2 = path[index + 1];
          lastAngle = this.getOffSetAngle(point[0], point[1], point2[0], point2[1]);
        }

        const newCoordinate = this.getNewCoordinatesByAngle(lastAngle, point[0], point[1])
        newPath.push(newCoordinate)
      }
    }
    else {
        try {
          const converted = toETRSTM35FIN(path[0][0], path[0][1]);
          let roadData = await getRoadData(converted.y, converted.x, 10);
          const roadNumber = roadData.road;
          const roadPart = roadData.part;
          let roadDistance = roadData.distance;
          const anotherPointDistance = 10;

          if (roadDistance < anotherPointDistance) {
            roadDistance = roadDistance + anotherPointDistance;
          }
          else {
            roadDistance = roadDistance - anotherPointDistance;
          }

          let coordinates = await getRoadCoordinates(roadNumber, roadPart, roadDistance);

          const anotherCoordinates = toWGS84(coordinates.y, coordinates.x);

          const angle = this.getOffSetAngle(anotherCoordinates.latitude, anotherCoordinates.longitude,
                                            path[0][0], path[0][1]);
                 
          const newCoordinate = this.getNewCoordinatesByAngle(angle, path[0][0], path[0][1])
          newPath = [newCoordinate, newCoordinate]
        } catch(error) {
          return path;
        }
    }

    return newPath;
  }

  getOffSetAngle(lat1, lon1, lat2, lon2) {
    const dLon = lon2 - lon1;
    const y = Math.sin(dLon) * Math.cos(lat2);
    const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) *
              Math.cos(lat2) * Math.cos(dLon);
    let angle = Math.atan2(y, x);
    angle = toDegrees(angle);
    angle = (angle + 360 - 90) % 360;
    return toRadians(angle);
  }

  getNewCoordinatesByAngle(angle, latitude, longitude) {
    const R = 6378100; // Radius of the Earth
    const distanceBetween = 5;

    const lat1 = toRadians(latitude);
    const lon1 = toRadians(longitude);

    let newLatitude = Math.asin(Math.sin(lat1) * Math.cos(distanceBetween / R) +
                Math.cos(lat1) * Math.sin(distanceBetween / R) * Math.cos(angle));

    let newLongitude = lon1 + Math.atan2(Math.sin(angle) * Math.sin(distanceBetween / R) * Math.cos(lat1),
                                      Math.cos(distanceBetween / R) - Math.sin(lat1) * Math.sin(newLatitude));

    newLatitude = toDegrees(newLatitude);
    newLongitude = toDegrees(newLongitude);

    return [newLatitude, newLongitude];
  }

  selectMacadam(sensorData) {
    this.setState({
      state: 0
    }, () => {
      const selectedMacadam = this.props.macadams.find(
        macadam => macadam.get('id') === sensorData.get('macadam_id'));

      let selectedPath;

      for (let path of selectedMacadam.get('paths')) {
        if (new Date(sensorData.get('time')) - new Date(path.get('date'))) {
          selectedPath = path.get('id');
        }
        else {
          break;
        }
      }

      this.setState({
        selectedPath: selectedPath
      }, () => {
        this.setState({
          selectedPath: null
        });
      });
    });
  }

  addSensorDataToMap(values) {
    if (values.length === 0) {
      return;
    }

    const middleValue = values[Math.floor(values.length / 2)];

    this.setState({
      mapPosition: [middleValue.latitude, middleValue.longitude],
      sensorValues: values,
      state: 0
    });
  }

  render() {
    let id = null;
    let name = null;

    if (this.props.selectedConstructionSite != null) {
       id = this.props.selectedConstructionSite.get('id');
       name = this.props.selectedConstructionSite.get('name');
    }

    return (
      <div>
        <div className="center">
          <h1>Murskeet</h1>
          <ConstructionSiteLatestSelect get='macadams'/>
        </div>
        <br/>
        <div className='container'>
          <div className="row">
            <div className="column">
              <ContractSelect store={this.props.store} />
            </div>
            <div className="column">
              <ConstructionSiteSelect store={this.props.store} />
            </div>
          </div>
          <div className="row">
            <div className="column">
              <TimeRange store={this.props.store} />
            </div>
            <div className="column">
              <button onClick={this.toggleMakeExcel} disabled={id == null || this.props.macadams.size === 0}>Luo raportti</button>
            </div>
          </div>
        </div>
        <div className='wide-area'>
          <fieldset id="data">
            <legend>
              <div className={"state" + (this.state.state === 0 ? ' selected' : '')}
                   onClick={this.state.state === 0 ? null : this.changeState.bind(null, 0)}>
                Kartta
              </div>
              <div className={"state" + (this.state.state === 1 ? ' selected' : '')}
                   onClick={this.state.state === 1 ? null : this.changeState.bind(null, 1)}>
                Lista
              </div>
              <div className={"state" + (this.state.state === 2 ? ' selected' : '')}
                   onClick={this.state.state === 2 ? null : this.changeState.bind(null, 2)}>
                Sensoritiedot
              </div>
            </legend>
            <MapOrListOrSensors state={this.state.state} macadams={this.props.macadams} site={this.props.selectedConstructionSite}
                      loading={this.state.loading} page={this.state.page} changePage={this.changePage}
                      mapPaths={this.state.mapPaths} mapZoom={this.state.mapZoom}
                      mapPosition={this.state.mapPosition} selectMacadam={this.selectMacadam}
                      selectedPath={this.state.selectedPath}
                      addSensorDataToMap={this.addSensorDataToMap}
                      sensorValues={this.state.sensorValues} />
          </fieldset>
        </div>
        { name != null ?
          <Excel show={this.state.makeExcel} toggle={this.toggleMakeExcel}
                    name={name.length > 31 ? id.toString() : name}
                    headers={['Kohde', 'Aika', 'Tienumero', 'Aloitus - lopetus', 'Suunta / ajorata / kaista', 'Pituus (m)',
                              'Rekka', 'Kuorman massa', 'Kerroin', 'Korkeus (mm)', 'Lajike', 'Monttu', 'Huomiot']}
                    dataHeaders={['construction_site.name', 'date', 'roadPaths', 'roadPositions', 'length',
                                  'truck.register_number', 'truck_mass', 'multiplier', 'height', 'variety', 'pit', 'attentions']}
                    timeField={'date'}
                    data={this.props.macadams}/>
          : null 
        }
      </div>
    );
  }
}

export default connect(state => ({
  selectedContract: state.contractSelect.get('selectedContract'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
  timeRangeStart: state.timeRange.get('startTime'),
  timeRangeEnd: state.timeRange.get('endTime'),
  macadams: state.macadam.get('macadams'),
}), { clearMacadams, addMacadams, showMessage, addMacadam, removeMacadam,
      changeMacadam})(Macadams);

import React from 'react';
import { connect } from 'react-redux';
import { showMessage } from '../../../../src/message/Actions';
import { showConfirm } from '../../../../src/confirm/Actions';
import { showNotice } from '../../../../src/notice/Actions';
import ContractSelect from '../../../../src/contractSelect/ContractSelect';
import ConstructionSiteSelect from '../../../../src/constructionSiteSelect/ConstructionSiteSelect';               
import { fetch, fetchSensorData, paddedNumber, stateValueParser,
         toETRSTM35FIN, timer, WebWorker, getRoadData } from '../utils';
import worker from "./SensorDataWorker.js";
import senderWorker from "./SensorDataSenderWorker.js";
import './Sensor.css';
import TimeRange from '../../../../src/timeRange/TimeRange.js';



const CombineLoader = props => {
  if (!props.show) return null;

  return (
    <div className='modal'>
      <div id='combine-loader'>
        <h4>Liitetään dataa...</h4>
        <h5>Älä sulje selainta</h5>
        <div className='loader' />
      </div>
    </div>
  );
};

class SensorDataCombine extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sensorDevices: [],
      selectedSensorDevice: null,
      angles: [],
      widths: [],
      leftPots: [],
      rightPots: [],
      latitudes: [],
      longitudes: [],
      maxLength: 0
    };

    this.confirmSendSensorData = this.confirmSendSensorData.bind(this);
    this.sendSensorData = this.sendSensorData.bind(this);
  }

  componentDidMount() {
    this.dataWorker = new WebWorker(worker);
    this.dataSenderWorker = new WebWorker(senderWorker);

    this.dataWorker.addEventListener('message', e => {
      if (e.data.length != null) {
        this.setState({
          angles: e.data[0],
          widths: e.data[1],
          leftPots: e.data[2],
          rightPots: e.data[3],
          nothingToSend: e.data.length === 0,
          loadingSensorValues: false
        }, () => {
          this.setCalibrationValues();
        });
      }
      else {
        const sensorValueLoadingCount = e.data;
        this.setState({sensorValueLoadingCount: sensorValueLoadingCount });
      }
    });

    this.dataSenderWorker.addEventListener('message', async e => {
      const data = e.data;

      if (data.length == null) {
        this.sensorValueLoadingCount++;
        this.setState({ sensorValueLoadingCount: this.sensorValueLoadingCount });

      }
      else {
        const dataURL = data[0];
        let value = data[1];
        let error = 0;

        try {
          const converted = toETRSTM35FIN(value.latitude, value.longitude);
          const roadData = await getRoadData(converted.y, converted.x);
          value.road_number = roadData.road;
          value.road_part = roadData.part;
          value.road_distance = roadData.distance;
          value.construction_site_id = this.props.selectedConstructionSite.get('id');
          await fetch(dataURL, 'POST', value);
        } catch(e) {
          error = 1;
        }

        this.errorValues += error;
        this.sensorValueLoadingCount++;

        this.setState({
          sensorValueLoadingCount: this.sensorValueLoadingCount,
        });
      }

      if (this.state.sensorValueCount === this.sensorValueLoadingCount) {
        if (this.errorValues === 0) {
          this.props.showMessage('Suoritettu', 'Kaikki data liitetty', 'Success');
        }
        else {
          this.props.showMessage('Huom', this.errorValues + ' arvoa ei onnistettu liittämään. Kokeile myöhemmin uudelleen', 'Warning');
        }

        this.setState({ combining: false });
      }
    });
  }

  componentWillUnmount() {
    this.dataWorker.terminate();
    this.dataSenderWorker.terminate();
  }

  componentDidUpdate(lastProps, lastState) {
    if (lastProps.timeRangeStart !== this.props.timeRangeStart ||
        lastProps.timeRangeEnd !== this.props.timeRangeEnd) {
      this.getSensorDevices();
    }
    if (lastState.selectedSensorDevice !== this.state.selectedSensorDevice) {
      this.getSensorValuesWithPositions();
    }
  }

  jsonToObject(csv) {
    let arr = csv.replace('\r', '').split('\n'); 
    let jsonObj = [];
    let headers = arr[0].split(',');


    for (let i = 1; i < arr.length; i++) {
      let data = arr[i].split(',');
      let obj = {};

      for (let j = 0; j < data.length; j++) {
        if (j === headers.length) {
          break;
        }

        obj[headers[j].trim()] = data[j].trim();
      }

      jsonObj.push(obj);
    }

    return jsonObj;
  }

  async getSensorDevices() {
    if (this.props.timeRangeStart === '') {
      this.setState({sensorDevices: []});
      return;
    }

    this.setState({
      loadingSensorDevices: true,
      selectedSensorDevice: null,
      angles: [],
      widths: [],
      leftPots: [],
      rightPots: [],
      latitudes: [],
      longitudes: [],
      nothingToSend: true,
    });

    let startDate = new Date(this.props.timeRangeStart + 'Z');
    const timezoneOffset = startDate.getTimezoneOffset() / 60;
    startDate.setHours(startDate.getHours() + timezoneOffset);
    const startTime = startDate.toISOString();

    let endDate = new Date(this.props.timeRangeEnd + 'Z');
    endDate.setHours(endDate.getHours() + timezoneOffset);
    const endTime = endDate.toISOString();

    let csv;

    try {
      csv = await fetchSensorData(startTime, endTime);
    } catch(error) {
      this.setState({ loadingSensorDevices: false });
      this.props.showNotice('Yhteys virhe', 'Error');
    }

    if (csv == null) return;

    const jsonObj = this.jsonToObject(csv);

    const devices = jsonObj.filter(field => field['device'] != null);

    let deviceNames = [];

    for (let index in devices) {
      deviceNames[index] = devices[index]['device'];
    }

    this.setState({
      sensorDevices: deviceNames,
      loadingSensorDevices: false
    });
  }

  async getSensorValues() {
    let startDate = new Date(this.props.timeRangeStart + 'Z');
    const timezoneOffset = startDate.getTimezoneOffset() / 60;
    startDate.setHours(startDate.getHours() + timezoneOffset);
    const startTime = startDate.toISOString();

    let endDate = new Date(this.props.timeRangeEnd + 'Z');
    endDate.setHours(endDate.getHours() + timezoneOffset);
    const endTime = endDate.toISOString();

    let csv;

    try {
      csv = await fetchSensorData(startTime, endTime,
                                  this.state.selectedSensorDevice);
    } catch(error) {
      this.props.showNotice('Yhteys virhe', 'Error');
    }

    if (csv == null) return null;

    const sensorData = this.jsonToObject(csv);

    const angles = sensorData.filter(field => field['_field'] === 'Angle');
    const widths = sensorData.filter(field => field['_field'] === 'WorkWidth');
    const leftPots = sensorData.filter(field => field['_field'] === 'LeftPot');
    const rightPots = sensorData.filter(field => field['_field'] === 'RightPot');
    const latitudes = sensorData.filter(field => field['_field'] === 'lat');
    const longitudes = sensorData.filter(field => field['_field'] === 'lon');

    return {
      angles: angles,
      widths: widths,
      leftPots: leftPots,
      rightPots: rightPots,
      latitudes: latitudes,
      longitudes: longitudes
    }
  }

  async getSensorValuesWithPositions() {
    if (this.state.selectedSensorDevice === '') {
      this.setState({
        angles: [],
        widths: [],
        leftPots: [],
        rightPots: [],
        latitudes: [],
        longitudes: [],
        nothingToSend: true
      });
      return;
    }

    this.setState({
      loadingSensorValues: true,
      sensorValueLoadingCount: 0,
      sensorValuesCount: 0
    });

    const sensorValues = await this.getSensorValues();

    let angles = sensorValues.angles;
    let widths = sensorValues.widths;
    let leftPots = sensorValues.leftPots;
    let rightPots = sensorValues.rightPots;
    let latitudes = sensorValues.latitudes;
    let longitudes = sensorValues.longitudes;

    let maxLength = Math.max(angles.length, widths.length, leftPots.length, rightPots.length);

    if (maxLength === 0) return;

    let mostValues;

    if (maxLength === angles.length) {
        mostValues = angles;
    }
    else if (maxLength === widths.length) {
        mostValues = widths;
    }
    else if (maxLength === leftPots.length) {
        mostValues = leftPots;
    }
    else if (maxLength === rightPots.length) {
        mostValues = rightPots;
    }

    for (let index = 0; maxLength > index; index++) {
      const value = mostValues[index];

      if (value == null) {
        continue;
      }

      const valueTime = new Date(value['_time']);
      let locationIndex;

      for (let latIndex in latitudes) {
        const latitude = latitudes[latIndex];
        const latitudeTime = new Date(latitude['_time']);

        if (valueTime < latitudeTime) {
          break;
        }

        locationIndex = latIndex;
      }

      if (locationIndex == null) {
        if (index < angles.length) {
          angles.splice(index, 1);
        }
        if (index < widths.length) {
          widths.splice(index, 1);
        }
        if (index < leftPots.length) {
          leftPots.splice(index, 1);
        }
        if (index < rightPots.length) {
          rightPots.splice(index, 1);
        }

        index--;
        maxLength--;
        continue;
      }

      const latitude = parseFloat(latitudes[locationIndex]['_value']);
      const longitude = parseFloat(longitudes[locationIndex]['_value']);
      const coordinates = {latitude: latitude, longitude: longitude};

      if (index < angles.length) {
        angles[index]['location'] = coordinates;
      }
      if (index < widths.length) {
        widths[index]['location'] = coordinates;
      }
      if (index < leftPots.length) {
        leftPots[index]['location'] = coordinates;
      }
      if (index < rightPots.length) {
        rightPots[index]['location'] = coordinates;
      }
    }

    this.dataWorker.postMessage([sensorValues, maxLength]);

    this.setState({
      sensorValuesCount: maxLength,
      sensorValueLoadingCount: 0
    });
  }

  confirmSendSensorData() {
    if (this.props.selectedConstructionSite === null) {
      this.props.showNotice('Valitse kohde', 'Warning');
      return;
    }

    const startDate = new Date(this.props.timeRangeStart);
    const startTime = startDate.getDate() + '.' + (startDate.getMonth() + 1) +
      '.' + startDate.getFullYear() + ' ' + paddedNumber(startDate.getHours()) +
      ':' + paddedNumber(startDate.getMinutes());

    const endDate = new Date(this.props.timeRangeEnd);
    const endTime = endDate.getDate() + '.' + (endDate.getMonth() + 1) +
      '.' + endDate.getFullYear() + ' ' + paddedNumber(endDate.getHours()) +
      ':' + paddedNumber(endDate.getMinutes());

    this.props.showConfirm('Yhdistetäänkö laitteen ' + this.state.selectedSensorDevice + 
      ' data ajalta ' + startTime + ' - ' + endTime + ' kohteeseen ' + this.props.selectedConstructionSite.get('name') + '?', 
      this.sendSensorData);
  }

  sendSensorData() {
    timer(0).then(async () => {
      this.setState({combining: true});

      let angles = this.state.angles;
      let widths = this.state.widths;
      let leftPots = this.state.leftPots;
      let rightPots = this.state.rightPots;

      const allLength = angles.length +
                        widths.length +
                        leftPots.length +
                        rightPots.length;

      this.setState({
        combining: true,
        sensorValueLoadingCount: 0,
        sensorValueCount: allLength - 1
      });

      const sensorValues = {
        angles: angles,
        widths: widths,
        leftPots: leftPots,
        rightPots: rightPots
      };

      const selectedConstructionSiteID = this.props.selectedConstructionSite.get('id');
      let existingAngles;
      let existingWidths;
      let existingpotHeights;

      try {
        const angleUrl = '/angle?site=' + selectedConstructionSiteID;
        existingAngles = await fetch(angleUrl);

        const widthUrl = '/width?site=' + selectedConstructionSiteID;
        existingWidths = await fetch(widthUrl);

        const potHeightUrl = '/pot?site=' + selectedConstructionSiteID;
        existingpotHeights = await fetch(potHeightUrl);
      } catch(error) {
        this.props.showMessage('Virhe', 'Palvelimeen ei saatu yhteyttä', 'Error');
        this.setState({combining: false});
        return;
      }

      this.errorValues = 0;
      this.sensorValueLoadingCount = 0;

      this.dataSenderWorker.postMessage([
        sensorValues,
        existingAngles,
        existingWidths,
        existingpotHeights
      ]);
    });
  }

  changeState(propertyName, type, defaultValue, event) {
    const value = stateValueParser(event, type, defaultValue);

    if (value == null) {
      return;
    }
    
    this.setState({[propertyName]: value});

    if (typeof(Storage) !== 'undefined') {
      localStorage[propertyName] = value;
    }
  }

  render() {
    return (
      <div>
        <div className="center">
          <h1>Liitä sensoridata kohteeseen</h1>
        </div>
        <div className='container'>
          <div className="row">
            <div className="column">
              <ContractSelect store={this.props.store} />
            </div>
            <div className="column">
              <ConstructionSiteSelect store={this.props.store} />
            </div>
          </div>
          <div className="row">
            <div className="column">
              <TimeRange store={this.props.store} />
            </div>
            <div className="column">
            {this.state.loadingSensorDevices ?
              <div className='loader' />
            :
              <label>
                Sensorilaite
                <select onChange={this.changeState.bind(this, 'selectedSensorDevice', 'string', null)} 
                        value={this.state.selectedSensorDevice} >
                <option value={''}>Valitse laite</option>
                  { this.state.sensorDevices.map(device => (
                      <option key={device} value={device}>
                        {device}
                      </option>
                    ))
                  }
                </select>
              </label>
            }
            </div>
          </div>
          { this.state.loadingSensorValues ?
            <div className='loader' />
          :
            <button onClick={this.confirmSendSensorData} disabled={this.state.maxLength === 0}>
              Liitä
            </button>
          }
        </div>
        <CombineLoader show={this.state.combining} />
      </div>
    );
  }
}

export default connect(state => ({
  selectedContract: state.contractSelect.get('selectedContract'),
  selectedConstructionSite: state.constructionSiteSelect.get('selectedConstructionSite'),
  timeRangeStart: state.timeRange.get('startTime'),
  timeRangeEnd: state.timeRange.get('endTime'),
  organizationId: state.login.get('user') ? state.login.get('user').get('organizationId') : null,
}), { showMessage, showConfirm, showNotice })(SensorDataCombine);

import React from 'react';
import './Watermark.css'
import '../styles.css'


const Watermark = props => (
  <div id='watermark'>
    <span>Made by</span>
    <img alt='Nevia' src='../nevia-logo.png'/>
  </div>
);

export default Watermark;
